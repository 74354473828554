import React, { useState, useEffect, ReactNode, FC } from 'react';
import PropTypes from 'prop-types';
import { StaticQuery, graphql } from 'gatsby';
import balanceText from 'balance-text';
import 'typeface-source-sans-pro';

import SEO from './seo';
import ogImage from '../images/people-02-tablet.jpg';

import Header from './header';
import Footer from './footer';
import MobileNav from './mobileNav/mobileNav';

import 'bootstrap/dist/css/bootstrap.min.css';

import './layout.scss';

type LayoutProps = {
  children?: ReactNode;
  doHideFooter?: boolean;
};

const Layout: FC<LayoutProps> = ({ children, doHideFooter }) => {
  const [navActive, setNavActive] = useState(false);

  const [footerHidden, setFooterHidden] = useState(false);

  useEffect(() => {
    if (doHideFooter) {
      setFooterHidden(true);
    } else {
      setFooterHidden(false);
    }
  }, [doHideFooter]);

  useEffect(() => {
    balanceText();
  }, []);

  const handleMobileNavToggle = () => {
    setNavActive(true);
  };

  const handleMobileNavClose = () => {
    setNavActive(false);
  };

  return (
    <StaticQuery
      query={graphql`
        query SiteTitleQuery {
          site {
            siteMetadata {
              title
            }
          }
        }
      `}
      render={data => (
        <div
          className={`site-container ${navActive ? 'mobile-nav-active' : ''} ${
            footerHidden ? 'footer-hidden' : ''
          }`}
        >
          <SEO image={ogImage} title="UTUC" />
          <Header onMobileNavToggle={handleMobileNavToggle} />
          <main className="site-main">{children}</main>
          <Footer />
          <MobileNav
            onMobileNavClose={handleMobileNavClose}
            navActive={navActive}
          />
        </div>
      )}
    />
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
